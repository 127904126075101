import { call, put } from "redux-saga/effects"
import shopApi from "../services/ShopifyApi"
import cloudApi from "../services/CloudinaryApi"
import { GlassSongPlaqueAttributesTransform } from "../transforms/GlassSongPlaqueTransform"

export function* addGlassSongPlaque(action) {
  const { plaqueState, plaqueVariantID } = action
  const { croppedPhotoURI, standID } = plaqueState

  try {
    yield put({ type: "USER_ADD_TO_CART" })
    const photoURI = yield call(cloudApi.uploadImage, croppedPhotoURI)
    const customAttributes = GlassSongPlaqueAttributesTransform(
      plaqueState,
      photoURI
    )
    let payload = yield call(
      shopApi.addToCart,
      customAttributes,
      plaqueVariantID
    )
    if (standID) {
      payload = yield call(shopApi.addPlaqueStand, standID)
    }
    yield put({
      type: "ADD_VARIANT_TO_CART",
      payload,
      message: "Added To Cart!",
    })
    yield put({ type: "RESTART_CART" })
  } catch (e) {
    yield put({
      type: "ADD_VARIANT_TO_CART_FAILURE",
      message: "Error adding item.",
    })
    yield put({ type: "RESTART_CART" })
  }
}

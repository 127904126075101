import { call, put } from 'redux-saga/effects'
import spotifyApi from '../services/SpotifyApi'
import timeFunctions from '../utilities/time/time'
import { spotifyCleanup } from '../transforms/SpotifyTransforms'

export function * getAccessToken() {
    try {
        const response = yield call(spotifyApi.getAccessToken)
        if (response.status === 200) {
            const expireDate = timeFunctions.expireDate(response.data.expires_in)
            let payload  = {
                ...response.data,
                expireDate
            }
            yield put({type: 'SET_SPOTIFY_ACCESS_TOKEN',  payload, message: "Success in retrieving Spotify token"});
        }
    } catch (e) {
        yield put({type: 'SET_SPOTIFY_ACCESS_TOKEN_FAILURE', message: 'Error getting Spotify access token.'});
    }
}

export function * querySpotify(action) {
    try {
        if (action.query !== "") {
            const response = yield call(spotifyApi.searchSpotify, action.query)
            if (response.status === 200) {
                let results = spotifyCleanup(response.data)
                yield put({type: 'SET_SPOTIFY_RESULTS', results});
            }
        } 
    } catch (e) {
        yield put({type: 'SPOTIFY_QUERY_FAILURE', message: 'Error searching Spotify.'});
    }
}
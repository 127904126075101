import { graphql, useStaticQuery } from "gatsby"
import { Link } from "../Links"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import MenuMobile from "./MenuMobile/MenuMobile"
import { PageLayout } from '../../Layouts'
import { FaBars, FaShoppingCart } from "react-icons/fa"
import { getCartState } from "../../../../redux/accessors"
import { Transition } from "../../../../styles"
import { isMobile } from "../../../../hooks"

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { inCart } = useSelector(getCartState)
  const mobile = isMobile()
  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
    <div className="py-8 relative shadow-lg mx-auto">
    <div className="bg-annmade-1.0-blue absolute -right-full -left-full inset-y-0 z-0" />
      <div className="flex justify-between items-center relative">
        <Link to="/" className={`group flex flex-row items-center content-center`}>
          <div className={`text-white text-lg font-semibold ${!mobile && `group-hover:text-annmade-1.0-gold`} ml-1 ${Transition.DEFAULT_COLORS}`}>
                AnnMade
              </div>
        </Link>

        {/* Mobile Menu Items */}
        <div className="flex flex-row items-center justify-center">
          <Link
            className={`sm:hidden h-6 w-auto 
            ${Transition.DEFAULT}
            text-white hover:text-gray-300 hover:border-gray-300 ${Transition.DEFAULT}
            fill-current -mt-2 mr-6 
            activeClassName="border-white text-gray-300 hover:border-gray-300`}
            to="/cart"
          >
            <div className="cart-container relative inline">
              {inCart > 0 &&                   
              <span class="h-3 w-3 relative">
                  <span class="animate-ping absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500 left-20-px top-0"/>
                  <span class="absolute rounded-full h-2 w-2 bg-red-500 left-20-px top-0"/>
                </span> }
              <FaShoppingCart className="inline text-2xl" />
            </div>
          </Link>
          <button
            className="sm:hidden"
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open Menu"
          >
            <FaBars className="h-6 w-auto text-white text-white hover:text-gray-200 fill-current -mt-1" />
          </button>
        </div>

        {/* Desktop menu items */}
        <div className="hidden sm:block"> 
          {site.data.menu.map((link, key) => {
            // Hides the home button in desktop
            return (link.to !== '/' ?
              <Link
                key={`menu_desktop_link${key}`}
                className={`
                ml-6 sm:ml-8 
                text-sm sm:text-base font-medium 
                px-px pb-2 
                border-b-2 border-transparent 
                ${Transition.DEFAULT}
                text-white hover:text-gray-200 hover:border-white`}
                activeClassName="border-annmade-1.0-gold text-gray-100 hover:border-white hover:text-gray-200"
                to={link.to}
              >
                {link.name}
              </Link> : null )})}

            <div className="cart-container relative inline ml-6 sm:ml-8 ">
              <Link
                className={`
                group
                text-sm sm:text-base 
                font-medium border-b-2 pb-2 border-transparent 
                ${Transition.DEFAULT}
                hover:text-gray-200 hover:border-white 
                `}
                activeClassName="border-annmade-1.0-gold text-gray-100 hover:border-white hover:text-gray-200"
                to="/cart"
              >
                {inCart > 0 && 
                  <span class="h-3 w-3 relative">
                  <span class="animate-ping absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500 left-20-px top-0"/>
                  <span class="absolute rounded-full h-2 w-2 bg-red-500 left-20-px top-0"/>
                </span> }
                  <FaShoppingCart className={`
                  inline 
                  text-2xl 
                  ${Transition.DEFAULT}
                  text-white group-hover:text-gray-200 group-hover:border-gray-300`} />
              </Link>
            </div>
        </div>
      </div>
      <MenuMobile
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={site.data.menu}
      />
    </div>
    </PageLayout>
  )
}

export default Header

// initial state
const initState = {
  isCartOpen: false,
  checkout: { lineItems: [] },
  inCart: 0,
  products: [],
  shop: {},
  posting: false,
  posted: false,
  addedToCart: false,
  error: null,
  message: null,
}

// actions
const CHECKOUT_FOUND = "CHECKOUT_FOUND"
const CHECKOUT_FAILURE = "CHECKOUT_FAILURE"

const PRODUCTS_FOUND = "PRODUCTS_FOUND"
const PRODUCTS_FAILURE = "PRODUCTS_FAILURE"

const USER_ADD_TO_CART = "USER_ADD_TO_CART"
const ADD_VARIANT_TO_CART = "ADD_VARIANT_TO_CART"
const ADD_VARIANT_TO_CART_FAILURE = "ADD_VARIANT_TO_CART_FAILURE"
const RESTART_ADDED_TO_CART = "RESTART_ADDED_TO_CART"

const UPDATE_LINE_ITEM = "UPDATE_LINE_ITEM"
const UPDATE_LINE_ITEM_SUCCESS = "UPDATE_LINE_ITEM_SUCCESS"
const UPDATE_LINE_ITEM_FAILURE = "UPDATE_LINE_ITEM_FAILURE"

const REMOVE_LINE_ITEM_IN_CART = "REMOVE_LINE_ITEM_IN_CART"
const REMOVE_LINE_ITEM_IN_CART_SUCCESS = "REMOVE_LINE_ITEM_IN_CART_SUCCESS"
const REMOVE_LINE_ITEM_IN_CART_FAILURE = "REMOVE_LINE_ITEM_IN_CART_FAILURE"

const RESTART_CART = "RESTART_CART"

// reducers
export default (state = initState, action) => {
  switch (action.type) {
    case PRODUCTS_FOUND:
      return {
        ...state,
        error: null,
        products: action.payload,
        posting: false,
      }
    case PRODUCTS_FAILURE:
      return { ...state, error: true, message: action.message }
    case CHECKOUT_FOUND:
      return {
        ...state,
        error: null,
        message: null,
        checkout: action.payload,
        inCart: action.payload.lineItems.length,
        posting: false,
        posted: false,
      }
    case CHECKOUT_FAILURE:
      return { ...state, error: true, message: action.message }
    case USER_ADD_TO_CART:
      return {
        ...state,
        message: "Adding to Cart...",
        posting: true,
        posted: false,
      }
    case ADD_VARIANT_TO_CART:
      return {
        ...state,
        error: null,
        message: action.message,
        checkout: action.payload,
        inCart: action.payload.lineItems.length,
        posting: false,
        posted: true,
        addedToCart: true,
      }
    case ADD_VARIANT_TO_CART_FAILURE:
      return {
        ...state,
        posting: false,
        posted: false,
        error: true,
        message: action.message,
      }
    case UPDATE_LINE_ITEM:
      return {
        ...state,
        error: null,
        message: "Updating Item...",
        posting: true,
        posted: false,
      }
    case UPDATE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.message,
        checkout: action.payload,
        inCart: action.payload.lineItems.length,
        posting: false,
        posted: true,
      }
    case UPDATE_LINE_ITEM_FAILURE:
      return {
        ...state,
        error: true,
        message: action.message,
        posting: false,
        posted: false,
      }
    case REMOVE_LINE_ITEM_IN_CART:
      return {
        ...state,
        error: null,
        message: "Removing Item...",
        posting: true,
        posted: false,
      }
    case REMOVE_LINE_ITEM_IN_CART_SUCCESS:
      return {
        ...state,
        error: null,
        message: action.message,
        checkout: action.payload,
        inCart: action.payload.lineItems.length,
        posting: false,
        posted: true,
      }
    case REMOVE_LINE_ITEM_IN_CART_FAILURE:
      return {
        ...state,
        posting: false,
        error: true,
        message: action.message,
        posted: false,
      }
    case RESTART_CART:
      return {
        ...state,
        posting: false,
        posted: false,
        error: null,
        message: null,
      }
    case RESTART_ADDED_TO_CART:
      return {
        ...state,
        addedToCart: false,
      }
    default:
      return state
  }
}

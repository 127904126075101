import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { FaTimes } from "react-icons/fa"

function Overlay({ children, isOpen, setIsOpen }) {
  function closeOnEscapeKey(event) {
    if (event.keyCode === 27 && isOpen) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", closeOnEscapeKey)
    return () => {
      window.removeEventListener("keydown", closeOnEscapeKey)
    }
  })

  useEffect(() => {
    document
      .querySelectorAll("body, html")
      .forEach(e => e.classList[isOpen ? "add" : "remove"]("overflow-hidden"))
  }, [isOpen])

  return (
    <div className={`
    fixed z-50 block bg-gray-900 text-white transition duration-300 ease-in-out
    ${isOpen ? `inset-0 opacity-100` : `opacity-0`}
    `}>
      <div className={`flex flex-col h-full max-h-full
      ${isOpen ? `block` : `hidden`}
      `}>
        <div className="fixed top-0 right-0 mt-4 mr-4">
          <button
            className={`text-white focus:outline-none select-none highlight-none
            transition duration-500 ease-in-out
            ${isOpen ? `opacity-100 block` : `opacity-0 hidden`}
            `}
            onClick={() => setIsOpen(false)}
          >
            <FaTimes className="h-8 w-auto fill-current" />
          </button>
        </div>
        <div className="flex flex-grow overflow-hidden">
          {children}
        </div>
      </div> 
      </div>
  )
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default Overlay

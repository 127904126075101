/*
Creates a Store and Provider.
This component is going to wrap our root-component in Gatsby.
*/
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './CreateStore'
import Alert from './Alert'

export default ({ element }) => (
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Alert>
          {element}
        </Alert>
      </PersistGate>
  </Provider>
);
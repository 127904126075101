import React from "react"
import { useDispatch } from "react-redux"
import { Button } from "../Buttons"

const SuccessModal = ({ open }) => {
  const dispatch = useDispatch()

  const designNewProductActions = () => {
    dispatch({
      type: "DESIGN_LAB_UPDATE",
      key: "openProductModal",
      value: true,
    })
    dispatch({ type: "RESTART_ADDED_TO_CART" })
    dispatch({
      type: "DESIGN_LAB_UPDATE",
      key: "content",
      value: [],
    })
  }

  const resetContentActions = () => {
    dispatch({ type: "DESIGN_LAB_UPDATE", key: "content", value: [] })
    dispatch({ type: "RESTART_ADDED_TO_CART" })
  }

  return (
    <div
      class={`${
        open ? `z-90` : `-z-100`
      } fixed inset-0 overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-75 p-4 lg:p-8
          ${
            open
              ? `transform opacity-100 transition ease-out duration-200 visible`
              : `transform opacity-0 transition ease-in duration-100 invisible`
          }
          `}
      tabindex="-1"
      role="dialog"
      aria-labelledby="tk-modal-simple"
      aria-hidden="false"
    >
      <div
        class={`flex flex-col rounded shadow-sm bg-white overflow-hidden w-full max-w-md mx-auto
        ${
          open
            ? `transform opacity-100 transition ease-out duration-200 visible`
            : `transform opacity-0 transition ease-in duration-100 invisible`
        }
          `}
        role="document"
      >
        <div class="p-5 lg:p-6 flex-grow w-full text-center">
          <div class="w-16 h-16 mx-auto mb-5 flex items-center justify-center rounded-full bg-green-100">
            <svg
              class="hi-solid hi-check inline-block w-8 h-8 text-green-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>
            <h4 class="text-xl font-semibold mb-1">Added to cart</h4>
            <p class="text-gray-600">
              Don't worry, you can add quantities to your order before checkout.
              How would you like to continue?
            </p>
          </div>
        </div>
        <div class="py-4 px-5 lg:px-6 space-y-4 w-full">
          <div
            role={`button`}
            tabIndex={0}
            onClick={designNewProductActions}
            onKeyDown={designNewProductActions}
          >
            <Button
              href={`/design-studio`}
              smallButton={true}
              theme={`LAUNCH-LIGHT`}
            >
              <span>Design a new product</span>
            </Button>
          </div>
          <div
            role={`button`}
            tabIndex={0}
            onClick={resetContentActions}
            onKeyDown={resetContentActions}
          >
            <Button smallButton={true} theme={`LAUNCH-DARK`}>
              <span>Start a new design</span>
            </Button>
          </div>
          <div
            role={`button`}
            tabIndex={0}
            onClick={resetContentActions}
            onKeyDown={resetContentActions}
          >
            <Button href={`/cart`} smallButton={true}>
              <span>Go to Cart</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SuccessModal

import React, { useState, useEffect } from "react"

const PageLayout = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])
  return loaded && <div className="container">{children}</div>
}

export default PageLayout

const axios = require('axios');

const cloudName = process.env.CLOUDINARY_CLOUD_NAME
const api_key = process.env.CLOUDINARY_KEY
const upload_preset = process.env.CLOUDINARY_UPLOAD_PRESET

const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

const uploadImage = (dataURL) => {

    const config = { responseType: 'blob' };
    return axios.get(dataURL, config).then(response => {
        
        return blobToBase64(response.data).then(buf => {

            let formData = new FormData();
            formData.append("api_key", api_key);
            formData.append("file", buf);
            formData.append("upload_preset", upload_preset);

            return axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData)
              .then(function (response) {
                return response.data.url
              })
              .catch(function (error) {
                return error
              });
        })
    }).catch(function (error) {
        return error
    });
}

const functions = {
    uploadImage,
}

export default functions
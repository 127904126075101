import React, { useRef } from "react"
import scaleImageMain from "../../../../utilities/image/scaleImage"

const ImageUploader = ( props ) => {

    const imageUploader = useRef(null);
    const { setPhotoURI, children } = props

    function handleChange(e) {
      let photoURI = null
      if (e.target.files.length > 0) {
        if (!e.target.files[0]) {
          return new Error("Error while uploading photo. Please try again")
        }
        photoURI = URL.createObjectURL(e.target.files[0])
        scaleImageMain(photoURI).then(newURI => {
          setPhotoURI(newURI)
        })
      } else {
        return new Error("Error while uploading photo. Please try again")
      }
    }
      
    return (
      <>
        <input 
          type="file" 
          accept="image/jpeg, image/jpg, image/png" 
          ref={imageUploader} 
          onChange={(e) => handleChange(e)}
          style={{
            display: "none"
          }}
          />
        <div role="button" tabIndex={0} className="w-full outline-none" onClick={() => imageUploader.current.click()} onKeyDown={() => imageUploader.current.click()}>
            {children}
        </div>
      </>
    )
}

export default ImageUploader
import React from 'react'
import { FaBan } from "react-icons/fa"
import Button from "./Button"

const SoldOutButton = () => {
    return (
        <Button
            classname="w-full mb-4"
            disabled={true}
        >
            <div className="flex flex-row items-center justify-center">
                <FaBan />
                    &nbsp;
                <div>Sold Out</div>
            </div>
        </Button>
    )
}

export default SoldOutButton
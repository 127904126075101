export const GlassSongPlaqueAttributesTransform = (item, photoURI) => {

    //let link = (<a aria-haspopup="dialog" data-modal="policy-terms-of-service" data-title-text="Terms of service" data-close-text="Close" data-iframe="true" href="/48744431767/policies/terms-of-service.html?locale=en">Terms of service</a>)

    let photoLink = document.createElement("a")
                  
    // Create the text node for anchor element. 
    var text = document.createTextNode("View"); 
      
    // Append the text node to anchor element. 
    photoLink.appendChild(text);  
      
    // Set the title. 
    photoLink.title = "This is Link";  
      
    // Set the href property. 
    photoLink.href = photoURI;
    
    const colorMap = {
        "white": "White",
        "black": "Black",
        "pink": "Pink",
        "yellow": "Yellow",
        "light-blue": "Light Blue",
        "mint-green": "Mint Green",
      }
       
    let customAttributes = [
        { key: '_Photo', value: photoURI },
        { key: 'Song', value: item.song },
        { key: 'Artist', value: item.artist },
        { key: 'Vinyl Color', value: colorMap[item.buttonColor] },
        { key: '_Photo-Link', value: photoURI },
    ]
    return customAttributes
}
import React, { useState } from "react";
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Overlay from "./Overlay"
import { useEffect } from "react";

const MenuMobile = ({ links, isOpen, setIsOpen }) => {
  const [x, setX] = useState(0)
  const [opacity, setOpacity] = useState(0)
  useEffect(() => {
    if (isOpen) {
      setTimeout( () => {
        setX(0);
        setOpacity(100);
      }, 100);
    } else {
      setOpacity(0);
      setX(-0.75);
    }
  }, [isOpen])
  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={`container flex flex-col justify-center`}>
        <ul className={`text-center flex flex-col justify-center`}>
          {links.map((link, key) => (
            <li
              className={`my-3
              transition-all ease-in-out
              ${!isOpen ? `translate-x-0` : `-translate-x-36`}`}
              style={{
              transform: `translateX(${x}rem)`,
              opacity: `${opacity}`,
              transition: `transform 300ms ease-in-out ${(key * 100)}ms, opacity 200ms ease-in-out ${(key * 100)}ms`,
              }}
              key={`menu_mobile_link${key}`}
            >
              <Link
                className={`font-semibold text-4xl text-white 
                `}
                activeClassName="text-annmade-1.0-gold"
                to={link.to}
                onClick={() => setIsOpen(false)}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Overlay>
  )
}

MenuMobile.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default MenuMobile

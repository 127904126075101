// initial state
const initState = {
    policy: null,
    fetching: false,
    fetched: false,
    error: null,
    message: null
}

// actions
const FETCH_POLICY = 'FETCH_POLICY'
const POLICY_SUCCESS = 'POLICY_SUCCESS'
const POLICY_FAILURE = 'POLICY_FAILURE'
const RESTART_STORE = 'RESTART_STORE'

// reducers
export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_POLICY:
            return { 
                ...state, 
                fetching: true,
                fetched: false,
             }
        case POLICY_SUCCESS: 
            return { ...state, 
                policy: action.payload.data,
                fetching: false,
                fetched: true,
                error: null,
                message: null
            }
        case POLICY_FAILURE:
            return { 
                ...state, 
                error: true,  
                message: action.message, 
                fetching: false,
                fetched: false,
             }
        case RESTART_STORE: 
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: null,
                message: null
            }
        default:
            return state
    }
}
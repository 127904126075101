import { call, put } from "redux-saga/effects"
import shopApi from "../services/ShopifyApi"
import html2canvas from "html2canvas"
import cloudApi from "../services/CloudinaryApi"
import { CustomGlasswareAttributesTransform } from "../transforms/CustomGlasswareTransform"

const isDEV = process.env.NODE_ENV === "development"

export function* addCustomGlassware(action) {
  try {
    yield put({ type: "USER_ADD_TO_CART" })
    const { designCupState } = action
    const { variantID, cupDOMId, isMobile } = designCupState

    let domId = isMobile ? `${cupDOMId}-Content` : cupDOMId
    let htmlElement = document.getElementById(domId)

    const canvas = yield call(
      () =>
        new Promise((resolve, reject) => {
          html2canvas(htmlElement, {
          }).then(function(canvas) {
            resolve(canvas)
          })
        })
    )

    const photoQuality = !isDEV ? 0.85 : 0.1

    const photoURI = yield call(
      cloudApi.uploadImage,
      canvas.toDataURL("image/jpeg", photoQuality)
    )

    const customAttributes = CustomGlasswareAttributesTransform(
      action,
      photoURI
    )
    let payload = yield call(shopApi.addToCart, customAttributes, variantID)

    yield put({
      type: "ADD_VARIANT_TO_CART",
      payload,
      message: "Added To Cart!",
    })

    yield put({ type: "RESTART_CART" })
  } catch (e) {
    yield put({
      type: "ADD_VARIANT_TO_CART_FAILURE",
      message: "Error adding item.",
    })
    yield put({ type: "RESTART_CART" })
  }
}

import PropTypes from "prop-types"
import React from "react"
import "typeface-inter"
import "../../../styles/style.css"
import { Footer, Header } from "../Gui/Nav"

/*
  This layout is wrapped around the whole app via gatsby-plugin-layout
  Rather than adding the layout per page (which causes rerendering and reduces performance)
*/
const Layout = ({ children, pageContext }) => {
  const { layout } = pageContext
  return (
    <>
      {layout === 'no-footer' ? 
        <>
          <Header />
          {children}
        </>
        :
        <>
        <Header />
          {children}
        <Footer />
        </>
      }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
}

export default Layout

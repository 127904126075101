import React, { useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMouseDown } from "../utilities/mouse-events"
import {
  getCartState,
  getSpotifyState,
  getDesignStudioState,
} from "../redux/accessors"
import AppWrappers from "./AppWrappers"
import dropDownListener from "../utilities/events/dropdown"
import deselectEventCheck from "../utilities/events/design-studio/deselectEventCheck"
import "plain-react-modal/dist/index.css"

const RootContainer = ({ children }) => {
  const dispatch = useDispatch()

  const { posting, posted, checkout } = useSelector(getCartState)
  const { editContentBottomSheet } = useSelector(getDesignStudioState)
  const { isOpen: isEditContentSheetOpen } = editContentBottomSheet
  const { expireDate } = useSelector(getSpotifyState)

  useEffect(() => {
    if (posting === false && posted === false) {
      dispatch({
        type: "CHECK_CHECKOUT",
        checkoutID: checkout.id,
      })
    }

    //Reinitialize token is expired
    if (expireDate) {
      let now = new Date()
      let expires = new Date(expireDate)
      if (now > expires) {
        dispatch({ type: "GET_SPOTIFY_ACCESS_TOKEN" })
      }
    } else {
      dispatch({ type: "GET_SPOTIFY_ACCESS_TOKEN" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    dispatch({ type: "FETCH_PRODUCTS" })
    dispatch({ type: "GET_SPOTIFY_ACCESS_TOKEN" })
    if (checkout.id) {
      dispatch({
        type: "CHECK_CHECKOUT",
        checkoutID: checkout.id,
      })
    } else {
      dispatch({ type: "CREATE_CHECKOUT" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deselectContent = useCallback(
    e => {
      if (deselectEventCheck(e)) {
        if (isEditContentSheetOpen) {
          dispatch({ type: "CLOSE_EDIT_CONTENT_SHEET" })
        } else {
          dispatch({
            type: "DESIGN_LAB_UPDATE",
            key: "selectedContentId",
            value: null,
          })
        }
      }
    },
    [isEditContentSheetOpen, dispatch]
  )

  // Deselects content based if content bottom sheet is open
  useMouseDown(deselectContent)
  // Closes dropdown menus
  useMouseDown(dropDownListener)

  return <AppWrappers>{children}</AppWrappers>
}

export default RootContainer

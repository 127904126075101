import React from 'react';


const ProductDisclaimer = ({ title, children }) => {
    
    return (
        <div className="my-2">
            <div className="text-xs text-gray-800 whitespace-pre-line">
                {title}
            </div>
            <div className="text-xs text-gray-600 whitespace-pre-line">
                <div className="inline">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default ProductDisclaimer
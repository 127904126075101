import { call, put } from "redux-saga/effects"
import shopApi from "../services/ShopifyApi"
import html2canvas from "html2canvas"
import cloudApi from "../services/CloudinaryApi"
import { DesignStudioAttributesTransform } from "../transforms/DesignStudioTransform"

const isDEV = process.env.NODE_ENV === "development"

// Vairiants
const urlToShopVariantKey = {
  "wine-glass": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODQ1MDU5ODI4MTM2Nw==",
  "shot-glass": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTM2NTQ5NDc5MjM0Mw==",
  "beer-mug": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zODQ1MDY3NjEwNTM2Nw==",
}

// let domId = isMobile ? `${cupDOMId}-Content` : cupDOMId
export function* addToCart(action) {
  try {
    yield put({ type: "USER_ADD_TO_CART" })
    const { productHtmlId } = action
    let htmlElement = document.getElementById(productHtmlId)
    const canvas = yield call(
      () =>
        new Promise((resolve, reject) => {
          html2canvas(htmlElement, {
            // windowWidth: htmlElement.scrollWidth,
            // windowHeight: htmlElement.scrollHeight
          }).then(function(canvas) {
            resolve(canvas)
          })
        })
    )

    const photoQuality = !isDEV ? 0.85 : 0.5

    const photoURI = yield call(
      cloudApi.uploadImage,
      canvas.toDataURL("image/jpeg", photoQuality)
    )
    const customAttributes = DesignStudioAttributesTransform({
      ...action,
      photoURI,
    })
    const payload = yield call(
      shopApi.addToCart,
      customAttributes,
      urlToShopVariantKey[productHtmlId]
    )
    yield put({
      type: "ADD_VARIANT_TO_CART",
      payload,
      message: "Added To Cart!",
    })

    yield put({ type: "RESTART_CART" })
  } catch (e) {
    yield put({
      type: "ADD_VARIANT_TO_CART_FAILURE",
      message: "Error adding item.",
    })

    yield put({ type: "DESIGN_STUDIO_UNLOCK_ALL_CONTENT" })
    yield put({ type: "RESTART_CART" })
  }
}

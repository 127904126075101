import { all, takeEvery, takeLatest } from 'redux-saga/effects'

//Sagas
import { createCheckout, getCheckout, fetchProducts, removeFromCart, updateItem, checkCheckout } from './CartSaga'
import { addGlassSongPlaque } from './SongPlaqueSaga'
import { addCustomGlassware } from './DesignCupSaga'
import { getPolicy } from './StoreSaga'
import { getAccessToken, querySpotify } from './SpotifySaga'
import { addToCart } from './DesignStudioSaga'

export default function* root() {
    yield all([
        takeLatest('CREATE_CHECKOUT', createCheckout),
        takeLatest('GET_CHECKOUT', getCheckout),
        takeLatest('CHECK_CHECKOUT', checkCheckout),
        takeLatest('FETCH_PRODUCTS', fetchProducts),
        takeLatest('GLASS_PLAQUE_ADD_TO_CART', addGlassSongPlaque), 
        takeEvery('UPDATE_LINE_ITEM', updateItem),
        takeEvery('REMOVE_LINE_ITEM_IN_CART', removeFromCart),
        takeLatest('FETCH_POLICY', getPolicy),
        takeLatest('GET_SPOTIFY_ACCESS_TOKEN', getAccessToken),
        takeLatest('UPDATE_SPOTIFY_QUERY', querySpotify),
        takeLatest('DESIGN_CUP_ADD_TO_CART', addCustomGlassware),
        takeLatest('DESIGN_STUDIO_ADD_TO_CART', addToCart)
    ])
} 
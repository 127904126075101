import React from 'react'
import Button from "./Button"
import { FaShoppingCart } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

//Condition prop is for when all required fields are met and the user can add to the cart
//Loading prop is for when the button is disabled after all conditions of the form are met
const AddToCartButton = ({ loading, conditions, addCart }) => {
    return (
        <div>
            {(!loading ?
                <Button
                    onClick={() => addCart()}
                    classname="w-full mb-4"
                    disabled={conditions}
                >
                    <div className="flex flex-row items-center justify-center">
                        <FaShoppingCart />
                    &nbsp;
                    <div className="text-base">Add to Cart</div>
                    </div>
                </Button>
                :
                <Button classname="w-full mb-4" disabled={true}>

                    <div className="flex flex-row items-center justify-center">
                        <AiOutlineLoading3Quarters size="1.25rem" className="animate-spin" />
                        &nbsp;
                        <div className="text-base">Adding...</div>
                    </div>
                </Button>

            )}
        </div>
    )
}

export default AddToCartButton
import { useState, useEffect } from "react"

const useIsMobile = () => {
  //Determines the users window size and changes layout accordingly
  const [isMobile, toggleMobile] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)

  //Handles resizing width
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  useEffect(() => {
    // 768 comes from medium screen widths
    // https://tailwindcss.com/docs/responsive-design
    if (width <= 768) {
      toggleMobile(true)
    } else {
      toggleMobile(false)
    }
  }, [width])

  return isMobile
}

export default useIsMobile

/**
  * Takes two date objects and returns the month difference between them
  * @param {Date} dateFrom Starting date
  * @param {Date} dateTo End date
  * @return {Number} differnce in months
*/
function monthDifference(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

/**
  * Given a number (in seconds) returns a new date object 
  * with the expiration date
  * @param {Number} Seconds Value in seconds
  * @return {Date} Date object with a future date
*/
function expireDate(seconds) {
    let futureDate = new Date()
    futureDate.setSeconds(futureDate.getSeconds() + seconds)
    return futureDate
}
const functions = {
    monthDifference,
    expireDate
}
export default functions
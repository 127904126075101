import createImage from "./createImage"

/**
  * Uses the canvas to rescale an image to produce a new URL
  * @param {Canvas} canvas canvas used to scale the image
  * @return {String} Image URL as a blob
*/
const createURLFromCanvas = canvas =>
    new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
    })

/**
  * Scales the image down 90% of the time until the width if below the accepted 
  * value. Two identical Image objects are passed in
  * https://ghinda.net/article/canvas-resize/
  * @param {Image} orgImage Original Image object from user uploaded image
  * @param {Image} newImage New Image object, identical to orgImage 
  *                         that will be used to be scaled down
  * @return {String} Image URL, either the original URL or the the new scaled down
*/
function scaler(orgImage, newImage) {

    //Scale is 90% of the original image
    let scale = .9
    let maxWidth = 1500

    //Aspect ratio of the original image is used to determine 
    //max height of the new scaled down image
    //https://eikhart.com/blog/aspect-ratio-calculator
    let aspectRatio = orgImage.width / orgImage.height
    let maxHeight = maxWidth / aspectRatio

    //The current width and height of the newImage
    //Starts off the same as orgImage
    let currWidth = newImage.width
    let currHeight = newImage.height

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    return new Promise(resolve => {

        //Resolve if the original image is okay
        if ((currWidth < maxWidth) && (currHeight < maxHeight)) {
            resolve({
                image: orgImage,
                canvas: null
            })
        } else {

            //Where the magic happens. Width and height are slowly broken down
            //and a new image is drawn using canvas
            while ((currWidth >= maxWidth) || (currHeight >= maxHeight)) {

                currWidth = currWidth * scale
                currHeight = currHeight * scale

                canvas.width = currWidth
                canvas.height = currHeight

                ctx.drawImage(newImage, 0, 0, canvas.width, canvas.height)
            }
        }
        resolve({
            image: newImage,
            canvas: canvas,
        })
    })
}

/**
  * Step Down Scale Downs an image 
  * then create a new URL from the promise returned
  * from the scaler
  * @param {Image} Original Image object
  * @return {String} Image URL
*/
const downScaleImage = (image) => {

    //Here we set a new Image object to be identical 
    //to the image sent in for redrawing
    let loadImage = new Promise((resolve, reject) => {
        let img = new Image()
        img.addEventListener('load', () => resolve(img))
        img.addEventListener('error', error => reject(error))
        img.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        img.src = image.src
    })

    return new Promise(resolve => {
        loadImage.then(img => {
            scaler(image, img).then(val => {
                if (val.canvas !== null) {
                    createURLFromCanvas(val.canvas).then(res => {
                        resolve(res)
                    })
                } else {
                    resolve(val.image.src)
                }
            })
        })
    })
}

/**
  * Takes the URL for a image and returns
  * a promise, which will contain either the original
  * URL (meaning the size is alright) OR a new URL
  * of the scaled down version
  * @param {String} imageSrc url for image
  * @return {String} Image URL
*/
export default async function scaleImageMain(imageSrc) {
    let image = await createImage(imageSrc)
    return new Promise(resolve => {
        downScaleImage(image).then(url => {
            resolve(url)
        })
    })
}
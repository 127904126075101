import { useState, useEffect } from "react"
import { Image } from "../../components/Global/Gui/Image"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const toDelay = {
  0: `300`,
  1: `500`,
  2: `700`,
}
const ProductCard = ({
  name,
  slug,
  summary,
  thumbnail,
  id,
  index,
  onScreen,
}) => {

  const [x, setX] = useState(0)
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    if (onScreen) {
      setTimeout(() => {
        setX(0)
        setOpacity(100)
      }, 100)
    } else {
      setOpacity(0)
      setX(0.75)
    }
  }, [onScreen])
  
  return (
    <div
      class={`flex flex-col rounded shadow-sm bg-white transform overflow-hidden`}
      style={{
        transform: `translateY(${x}rem)`,
        opacity: `${opacity}`,
        transition: `transform 300ms ease-in-out ${toDelay[index]}ms, opacity 400ms ease-in-out ${toDelay[index]}ms`,
        transitionDelay: 300,
      }}
      key={id}
    >
      <div class="p-5 lg:p-6 flex-grow w-full space-y-4">
        <Link
          to={`/${slug}`}
          class="block font-semibold text-gray-600 hover:text-gray-500"
        >
          {name}
        </Link>
        <Image
          layout={`fullWidth`}
          image={thumbnail.localFile.childImageSharp.gatsbyImageData}
          class="rounded-lg"
        />
        <p class="leading-relaxed text-gray-600 mt-2">{summary}</p>
        <div class="flex flex-col">
          <Link to={`/${slug}`}>
            <button
              type="button"
              class="w-full inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-6 rounded border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
            >
              <span>Learn More</span>
              <svg
                class="hi-solid hi-arrow-narrow-right inline-block w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    localFile: PropTypes.object,
  }),
}

export default ProductCard

export const query = graphql`
  fragment PortfolioCard on ContentfulPortfolio {
    id
    name
    slug
    thumbnail {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 444
            height: 342
            quality: 85
            layout: FULL_WIDTH
          )
        }
      }
    }
    summary
  }
`

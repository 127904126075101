import Client from 'shopify-buy';
import { store } from '../config/CreateStore'

const axios = require('axios');

// Initializing a client to return content in the store's primary language
const client = Client.buildClient({
    domain: process.env.SHOPIFY_STOREFRONT_NAME,
    storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN
})

const fetchProducts = () => {
    return client.product.fetchAll().then((products) => {
        // Do something with the products
        return products
    });
}

const createCheckout = () => {
    // Create an empty checkout
    return client.checkout.create().then((checkout) => {
        // Do something with the checkout
       return checkout
    });
}

const getCheckout = (checkoutID) => {
    return client.checkout.fetch(checkoutID).then((checkout) => {
        return checkout
    });
}

//Adds addition plaque stand for glass song plaque
const addPlaqueStand = (variantID) => {
    let checkoutState = store.getState().cart.checkout
    let checkoutID = checkoutState.id
    const lineItemToAdd = [
        {
          variantId: variantID,
          quantity: 1,
        }
      ];
    return client.checkout.addLineItems(checkoutID, lineItemToAdd).then((checkout) => {
        return checkout
  });
}

const addToCart = (attr = [], variantID) => {
    // Add an item to the checkout
    let checkoutState = store.getState().cart.checkout
    let checkoutID = checkoutState.id
    const lineItemToAdd = [
        {
          variantId: variantID,
          quantity: 1,
          customAttributes: attr,
        }
      ];
    return client.checkout.addLineItems(checkoutID, lineItemToAdd).then((checkout) => {
        // Do something with the updated checkout
        return checkout
  }).catch(err => {
      
  });
}

const removeFromCart = (lineItemID) => {
    // Remove an item from the checkout
    let checkoutState = store.getState().cart.checkout
    let checkoutID = checkoutState.id
    return client.checkout.removeLineItems(checkoutID, lineItemID).then((checkout) => {
        return checkout
    });
}

const updateLineItem = (lineItemID, variables) => {

    let checkoutState = store.getState().cart.checkout
    let checkoutID = checkoutState.id

    const lineItemsToUpdate = [{ 
        ...variables,
        id: lineItemID, 
    }];      
    //Update the line item on the checkout
    return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then((checkout) => {
        // Do something with the updated checkout
        return checkout
    })
}

const getPolicy = (query) => {
    return axios({
        method:'post',
        url: 'https://' + process.env.SHOPIFY_STOREFRONT_NAME + '/api/2020-10/graphql',
        timeout: 10000,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN
        },
        data: query
    }).then((res) => {
        return res.data
    })
}

const functions = {
    fetchProducts,
    createCheckout,
    getCheckout,
    addToCart,
    removeFromCart,
    updateLineItem,
    getPolicy,
    addPlaqueStand,
}

export default functions
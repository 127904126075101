import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaFacebook, FaInstagram, FaTwitter, FaPinterest } from "react-icons/fa"
import { Link } from '../Links'
import { Transition } from "../../../../styles"

const Footer = () => {
  const {
    site: {
      meta: { links },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            facebook
            instagram
            pinterest
            twitter
          }
        }
      }
    }
  `)

  let date = new Date()

  return (
    <div className="container">
      <div className="md:hidden">
        <div className="py-8 md:flex md:items-center md:justify-between">  
          <ul className="flex justify-around sm:justify-around  justify-center md:order-2">
            <FooterLink href={links.twitter} icon={FaTwitter} label="Twitter" />
            <FooterLink href={links.facebook} icon={FaFacebook} label="Facebook" />
            <FooterLink
              href={links.instagram}
              icon={FaInstagram}
              label="Instagram"
            />
            <FooterLink
              href={links.pinterest}
              icon={FaPinterest}
              label="Pinterest"
            />
          </ul>
          </div>
        </div>
      <div>
        <div className={`mt-8 py-8 
          flex
          flex-col sm:flex-col md:flex-row
          items-center 
          md:justify-around
          `}>
              <Link 
              to={`mailto:annmadeorders@gmail.com?subject=Customer Service Inquiry`}
              className={`p-2 text-center text-sm md:text-base text-annmade-1.0-gray hover:text-annmade-1.0-blue ${Transition.DEFAULT}`}
              >
                Contact
              </Link>
              <Link to={`/privacy`} 
              className={`p-2 text-center text-sm md:text-base text-annmade-1.0-gray hover:text-annmade-1.0-blue ${Transition.DEFAULT}`}
              >
                  Privacy
              </Link>
              <Link to={`/termsofservice`} 
              className={`p-2 text-center text-sm md:text-base text-annmade-1.0-gray hover:text-annmade-1.0-blue ${Transition.DEFAULT}`}
              >
                  Terms
              </Link>
              <Link to={`/refund`} className={`p-2 text-center text-sm md:text-base text-annmade-1.0-gray hover:text-annmade-1.0-blue ${Transition.DEFAULT}`}>
                  Refund
              </Link>
              <Link to={`/shipping`} className={`p-2 text-center text-sm md:text-base text-annmade-1.0-gray hover:text-annmade-1.0-blue ${Transition.DEFAULT}`}>
                  Shipping
              </Link>
          </div>
      </div>
      <div className="py-6 md:flex md:items-center md:justify-between">  
        <ul className="hidden md:flex md:justify-center md:order-2">
          <FooterLink href={links.twitter} icon={FaTwitter} label="Twitter" />
          <FooterLink href={links.facebook} icon={FaFacebook} label="Facebook" />
          <FooterLink href={links.instagram} icon={FaInstagram} label="Instagram"/>
          <FooterLink href={links.pinterest} icon={FaPinterest} label="Pinterest"/>
        </ul>
        <div className="md:mt-0 md:order-1">
          <p className="text-center text-sm md:text-base text-gray-700">
            &copy; {date.getFullYear()} AnnMade. All rights reserved.
          </p>
        </div>
      </div>
      </div>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="inline-block pl-0 sm:pl-0 md:pl-6">
      <Link to={href} className={`text-annmade-1.0-gray hover:text-annmade-1.0-blue ${Transition.DEFAULT}`}>
      <span className="sr-only">{label}</span>
        <Icon className="w-5 h-5 fill-current" />
      </Link>
    </li>
  )
}

export default Footer

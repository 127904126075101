import React from 'react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { AlertModal } from '../components/Global/Gui/Modals'

// configuration
const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 1500,
    transition: transitions.fade
  }

const Alert = ({children}) => {
    return (
        <AlertProvider template={AlertModal} {...options}>
            {children}
        </AlertProvider>
    )
}

export default Alert
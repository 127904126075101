// prevents deselecting for slider events
export const isSlider = ev => {
  const sliderNodes = document.getElementsByClassName(`semantic_ui_range_inner`)
  return Array.from(sliderNodes).some(node => node.contains(ev.target))
}

const deselectEventCheck = e => {
  if (
    !e.target.matches(".box-control-button") &&
    !e.target.matches(".draggable-content") &&
    !e.target.matches(".content-option") &&
    !isSlider(e)
  ) {
    return true
  }
  return false
}

export default deselectEventCheck

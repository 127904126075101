import React from "react"
import Link from "./Link"
import { Theme } from "../../../../constants"
import { Transition } from "../../../../styles"
import { FaLongArrowAltRight } from "react-icons/fa"

const ArrowLink = ({ children, to, theme, className = "", ...rest }) => {

  const linkThemeClasses = (t) => {
    switch(t) {
      case 'LAUNCH-DARK':
        return `font-semibold
        text-gray-800
        px-4 py-4 
        inline-flex justify-center items-center 
        ${Transition.DEFAULT} group`
      case 'LAUNCH-LIGHT':
        return `font-semibold
        text-white
        px-4 py-4 
        inline-flex justify-center items-center 
        ${Transition.DEFAULT} group`
      default:
        return `font-semibold
        text-${Theme.PRIMARY_BLUE}
        px-4 py-4 
        inline-flex justify-center items-center 
        ${Transition.DEFAULT} group`
    }
  }

  const arrowThemeClasses = (t) => {
    switch(t) {
      case 'LAUNCH-DARK':
        return `ml-2
        inline-block w-5 h-5 text-gray-800
        transform ${Transition.DEFAULT}
        group-hover:translate-x-0.5`
      case 'LAUNCH-LIGHT':
        return `ml-2
        inline-block w-5 h-5 text-white
        transform ${Transition.DEFAULT}
        group-hover:translate-x-0.5`
      default:
        return `ml-2
        inline-block w-5 h-5 text-${Theme.PRIMARY_BLUE}
        transform ${Transition.DEFAULT}
        group-hover:translate-x-0.5`
    }
  }

  return (
    <Link
      to={to}
      className={`${linkThemeClasses(theme)} ${className}` }
      {...rest}
    >
      {children}
      <FaLongArrowAltRight
        className={arrowThemeClasses(theme)}
      />
    </Link>
  )
}
export default ArrowLink
import _ from "lodash"

//Initial State
const initialState = {
  mainText: "",
  cup: null,
  fontStyle: "",
  fontColor: "",
  fontSize: 32,
  alignment: "text-center",
  variantID: null,
  position: { x: 0, y: 0 },
  cupDOMId: "DesignCupWineGlass",
  isMobile: true
}

//Action Creators
const DESIGN_CUP_UPDATE = "DESIGN_CUP_UPDATE"
const DESIGN_CUP_RESET_POSITION = "DESIGN_CUP_RESET_POSITION"

//Reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case DESIGN_CUP_UPDATE:
      let { key, value } = action
      return {
        ..._.set({ ...state }, key, value),
      }
    case DESIGN_CUP_RESET_POSITION:
      const { x, y } = state.prevPosition
      return {
        ...state,
        position : {
          x: x,
          y: y
        },
        prevPosition : {
          x: 0,
          y: 0
        }
      }
    default:
      return state
  }
}

/*
  Config for persisting redux state
*/
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

/*
  Redux-Persist Configuration
  Blacklist reducers here ONLY if we need to 
  blacklist entire state. 
  
  If specific fields 
  need to be saved, blacklist the reducer &
  wrap individual reducers in persistReducer(...)
  located in redux/index.js
  ex. Cart

  Whitelist is used to persist ENTIRE reducer state
*/
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['songPlaque', 'store', 'designCup', 'designStudio'],
  //whitelist: ['cart'],
}

export default persistConfig
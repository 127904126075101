/*
  Creates store and persistor for root element
*/
import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from '../redux/index';
import { persistStore, persistReducer } from 'redux-persist'
import sagaMiddleware from './ReduxSaga'
import persistConfig  from './ReduxPersist'
import rootSaga from '../sagas/index'

//Used to activate Redux Dev tools
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      trace: true
    }) : compose;

const enhancer = composeEnhancers(
// other store enhancers if any ex. applyMiddleware(...middleware),
  applyMiddleware(sagaMiddleware)
);

//Pull in root reducer for store creation
const persistedReducers = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducers, enhancer)

//Run .purge().then(res => { return res }) to completely purge store state
const persistor = persistStore(store)


//Run the saga
sagaMiddleware.run(rootSaga)

export { store, persistor }
import React from "react"
import Modal from 'plain-react-modal'

const MoreInfoModal = (props) => {
    const { setOpen, title, children } = props

    return (
      <Modal
        onClose={() => setOpen(false)}
        title={title}
        overlayColor='rgba(0,0,0,.85)'
        backgroundColor='#FFF'
      >
        {children}
      </Modal>
    )
}
export default MoreInfoModal
export const CustomGlasswareAttributesTransform = ({ designCupState }, photoURI) => {
  const { alignment, cup, fontColor, fontSize, fontStyle, mainText } = designCupState
  const { title } = cup
  const alignmentMap = {
    "text-left": "Left",
    "text-center": "Center",
    "text-right": "Right",
  }
  let customAttributes = [
    { key: "_FontSize", value: `${fontSize ? Math.floor(fontSize) : '32'}` },
    { key: "Cup", value: title },
    { key: "Style", value: fontStyle },
    { key: "Color", value: fontColor },
    { key: "_Content", value: mainText },
    { key: "Alignment", value: alignmentMap[alignment] },
    { key: '_Photo', value: photoURI },
    { key: '_Photo-Link', value: photoURI },
  ]
  return customAttributes
}

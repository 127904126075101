import { call, put } from 'redux-saga/effects'
import shopApi from '../services/ShopifyApi'
import timeFunctions from '../utilities/time/time'

export function* createCheckout(action) {
    try {
        const payload = yield call(shopApi.createCheckout)
        yield put({type: 'CHECKOUT_FOUND', payload, message: "Checkout successfully created!"});
    } catch (e) {
        yield put({type: "CHECKOUT_FAILURE", message: 'Error creating checkout'});
    }
}

//Note: Shopify holds onto checkouts for three months. We kept 
//our checkouts open for two months. 
export function* getCheckout(action) {
    try {
        const payload = yield call(shopApi.getCheckout, action.checkoutID)
        let today = new Date()
        let created = new Date(payload.createdAt)
        let difference = timeFunctions.monthDifference(created, today)

        if (difference < 3) {
            yield put({type: 'CHECKOUT_FOUND', payload, message: "Checkout Found!"});
        } else {
            yield put({type: 'CREATE_CHECKOUT'});
        }
    } catch (e) {
        yield put({type: "CHECKOUT_FAILURE", message: 'Error getting checkout'});
    }
}

//No State changes are made for checking the checkout to prevent infinte loop
export function* checkCheckout(action) {

    try {
        const payload = yield call(shopApi.getCheckout, action.checkoutID)

        //Create a new checkout if the checkout could not be found
        if (payload === null) {
            yield put({type: 'CREATE_CHECKOUT'});
            return
        }

        let today = new Date()
        let created = new Date(payload.createdAt)
        let difference = timeFunctions.monthDifference(created, today)

        if (payload.completedAt === null && difference < 3) {
            return
        } else {
            yield put({type: 'CREATE_CHECKOUT'});
        }
    } catch (e) {
        return
    }
}

export function* fetchProducts() {
    try {
        const payload = yield call(shopApi.fetchProducts)
        yield put({type: 'PRODUCTS_FOUND', payload, message: 'Products Found!'});
    } catch (e) {
        yield put({type: 'PRODUCTS_FAILURE', message: 'Error getting products'});
    }
}

export function* updateItem(action) {
    try {
        const payload = yield call(shopApi.updateLineItem, action.lineItemID, action.variables)
        yield put({type: 'UPDATE_LINE_ITEM_SUCCESS', payload, message: "Item Updated!"});
        yield put({type: 'RESTART_CART'});
    } catch (e) {
        yield put({type: 'UPDATE_LINE_ITEM_FAILURE', message: 'Update Failed. \n Please try again.'});
        yield put({type: 'RESTART_CART'});
    }
}

export function* removeFromCart(action) {
    try {
        const payload = yield call(shopApi.removeFromCart, action.lineItemID)
        yield put({type: 'REMOVE_LINE_ITEM_IN_CART_SUCCESS', payload, message:"Item Removed!"});
        yield put({type: 'RESTART_CART'});
    } catch (e) {
        yield put({type: 'REMOVE_LINE_ITEM_IN_CART_FAILURE', message: 'Error deleting item.'});
        yield put({type: 'RESTART_CART'});
    }
}
import PropTypes from "prop-types"
import React from "react"
import { Theme } from '../../../../constants'
import { Link } from '../Links'
import { Transition } from "../../../../styles"

const Button = ({ onClick, classname, color, theme, target, children, disabled, href, smallButton = false, ...params }) => {

  const buttonSizeClass = smallButton ? `px-3 py-2 w-full` : `px-6 py-4 w-max-content`
  
  const buttonTheme = (t) => {
    switch(t) {
      case 'LAUNCH-DARK':
        return `inline-flex justify-center items-center 
        space-x-2 border font-semibold focus:outline-none 
        ${buttonSizeClass} leading-6 rounded 
        ${disabled ? "bg-gray-400 pointer-events-none" : `bg-gray-800 border-gray-800`}
        text-white hover:text-white 
        focus:ring focus:ring-gray-800 focus:outline-none focus:shadow-outline 
        active:bg-gray-800 active:border-gray-800 
        hover:opacity-75
        ${Transition.DEFAULT}`
      case 'LAUNCH-LIGHT':
        return `inline-flex justify-center items-center 
        space-x-2 border font-semibold 
        ${buttonSizeClass} leading-6 rounded
        ${disabled ? "bg-gray-100 pointer-events-none" : `bg-white border-gray-300`}
        text-gray-800 shadow-sm hover:text-gray-800 
        hover:bg-gray-100 hover:border-gray-300 hover:shadow 
        focus:ring focus:ring-gray-500 focus:ring-opacity-25 focus:outline-none
        active:bg-white active:border-white active:shadow-none        
        hover:opacity-75
        ${Transition.DEFAULT}`
      case 'LAUNCH-CHECKOUT':
        return `
        mt-8 rounded-full py-2 px-4
        inline-flex justify-center items-center 
        space-x-2 border font-semibold 
        ${buttonSizeClass} leading-6 rounded
        ${disabled ? "bg-gray-400 pointer-events-none" : `bg-green-600 border-gray-300`}
        text-white hover:text-white 
        hover:shadow hover:opacity-75 
        focus:ring focus:ring-green-500 focus:ring-opacity-25 focus:outline-none
        active:bg-green-700 active:border-white active:shadow-none        
        hover:opacity-75
        ${Transition.DEFAULT}` 
      default:
        return `inline-flex justify-center items-center 
        space-x-2 border font-semibold focus:outline-none 
        ${buttonSizeClass} leading-6 rounded 
        ${disabled ? "bg-gray-400 pointer-events-none" : `bg-${Theme.PRIMARY_BLUE} border-${Theme.PRIMARY_BLUE}`}
        text-white hover:text-white 
        focus:ring focus:ring-${Theme.PRIMARY_BLUE} focus:outline-none focus:shadow-outline 
        active:bg-${Theme.PRIMARY_BLUE} active:border-${Theme.PRIMARY_BLUE}  
        hover:opacity-75
        ${Transition.DEFAULT}`
    }
  }

  const className = classname ? buttonTheme(theme) + " " + classname : buttonTheme(theme)

  if (href) {
    return (
      <Link to={href} className={className}> {children} </Link>
    )
  } else {
    return (
      <button type="button" className={className} {...params} disabled={disabled} onClick={onClick}>
        {children}
      </button>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
}

Button.defaultProps = {
  href: null,
}

export default Button

// initial state
const initState = {
  accessToken: null,
  query: "",
  scope: null,
  token_type: null,
  expireDate: null,
  results: [],
  fetching: false,
  fetched: false,
  error: null,
  message: null,
}

// actions
const SET_SPOTIFY_ACCESS_TOKEN = "SET_SPOTIFY_ACCESS_TOKEN"
const UPDATE_SPOTIFY_QUERY = "UPDATE_SPOTIFY_QUERY"
const CLEAR_SPOTIFY_QUERY = "CLEAR_SPOTIFY_QUERY"
const SET_SPOTIFY_RESULTS = "SET_SPOTIFY_RESULTS"
const SET_SPOTIFY_ACCESS_TOKEN_FAILURE = "SET_SPOTIFY_ACCESS_TOKEN_FAILURE"
const SPOTIFY_QUERY_FAILURE = "SPOTIFY_QUERY_FAILURE"

// reducers
export default (state = initState, action) => {
  switch (action.type) {
    case SET_SPOTIFY_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.access_token,
        expireDate: action.payload.expireDate,
        scope: action.payload.scope,
        token_type: action.payload.token_type,
      }
    case UPDATE_SPOTIFY_QUERY:
      return {
        ...state,
        query: action.query,
        fetching: true,
        fetched: false,
        results: [],
      }
    case CLEAR_SPOTIFY_QUERY:
      return {
        ...state,
        query: "",
        results: [],
      }
    case SET_SPOTIFY_RESULTS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        message: null,
        results: action.results,
      }
    case SET_SPOTIFY_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        error: true,
        message: action.message,
      }
    case SPOTIFY_QUERY_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
        fetched: false,
        message: action.message,
      }
    default:
      return state
  }
}

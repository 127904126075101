import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

/*
      query={graphql`
        {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      `}
*/

const Image = ({ filename, image, children }) =>
  filename ? (
    <StaticQuery
      query={graphql`
        {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      `}
      render={data => {
        const image = data.images.edges.find(n => {
          return n.node.name === filename
        })
        if (!image) {
          return null
        }
        let { childImageSharp } = image.node
        let { gatsbyImageData } = childImageSharp
        return <GatsbyImage image={gatsbyImageData} alt={filename || ""} placeholder="blurred" />
      }}
    />
  ) : (
    <GatsbyImage image={image} alt={image || ""} placeholder="blurred" />
  )

export default Image


/**
 * Takes the results from the Spotify API and consolidates it to one array
 * Also remove any additional information not needed by the UI
 * Information we need is Song Title, Artist, ID, and Cover Art
 * @param {Object} data - Raw data from Spotify
 * @return {Array} array
 */
export const spotifyCleanup = (data) => {
    try {
        let returnArr = []

        //For multiple artists, join to one string
        const artistJoin = (artists) => {

            if (!Array.isArray(artists)) {
                return new Error("Artists need to be an array")
            }

            //Since the max length is 32, we need have a max artist
            //string of 29 (to add ...)
            if (artists.length === 0) {
                return 'No Artist Specified'
            }
            let returnName = ""
            if (artists.length === 1) {
                returnName = artists[0]['name']
            } else {
                for (let i = 0; i < artists.length; i++) {
                    returnName += artists[i]['name']
                    returnName += (i !== artists.length - 1 ? ", " : "")
                }
            }
            return (returnName.length <= 32 ? returnName : returnName.substring(0, 29) + "...")
        }

        //Grabs the cover art for a track if it exists
        //based on the album object passed in
        const albumArt = (album) => {
            if (!album || album['images'] === null || album['images'].length === 0) {
                return null
            }

            return album['images'][0]['url']
            
        }

        for (const property in data) {
            let items = data[property]['items']
            for (let i = 0; i < items.length; i++) {
                let id = items[i]['id']
                let songTitle = (items[i]['name'].length <= 32 ? items[i]['name'] : items[i]['name'].substring(0, 29) + "...")
                let artist = artistJoin(items[i]['artists'])
                let photo = albumArt(items[i]['album'])
                returnArr.push({
                    id: id,
                    songTitle: songTitle,
                    artist: artist,
                    photo: photo
                })
            }

        }

        return returnArr

    } catch(error) {}
}
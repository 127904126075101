import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDesignStudioState } from "../../../../redux/accessors"
import { Theme } from "../../../../constants"
import { Image } from "../Image"
import { Button } from "../Buttons"

const ProductModal = ({ products }) => {
  const dispatch = useDispatch()
  const { openProductModal } = useSelector(getDesignStudioState)
  const [currentProduct, setCurrentProduct] = useState(null)
  return (
    <div
      class={`${
        openProductModal ? `z-90` : `-z-100`
      } fixed inset-0 overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-75 p-4 lg:p-8
      ${
        openProductModal
          ? `transform opacity-100 transition ease-in-out duration-500 visible`
          : `transform opacity-0 transition ease-in-out duration-100 invisible`
      }
      `}
      tabindex="-1"
      role="dialog"
      aria-labelledby="tk-modal-simple"
      aria-hidden="false"
    >
      <div
        class={`flex flex-col rounded shadow-sm bg-white overflow-hidden w-full max-w-md mx-auto
        ${
          openProductModal
            ? `transform opacity-100 transition ease-out duration-700 visible`
            : `transform opacity-0 transition ease-in duration-100 invisible`
        }
          `}
        role="document"
      >
        <div class="py-4 px-5 lg:px-6 w-full bg-gray-50 flex justify-between items-center">
          <h3 class="font-medium">Select a product</h3>
          <div class="-my-4">
            <button
              type="button"
              onClick={() =>
                dispatch({
                  type: "DESIGN_LAB_UPDATE",
                  key: "openProductModal",
                  value: false,
                })
              }
              class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm rounded border-transparent text-gray-600 hover:text-gray-400 focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:text-gray-600"
            >
              <svg
                class="hi-solid hi-x inline-block w-4 h-4 -mx-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="p-5 lg:p-6 flex-grow w-full">
          <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 lg:gap-8">
            {products && products.length > 0 ? (
              products.map(({ name, thumbnail, slug }, index) => (
                <button
                  class={`p-5 lg:p-6 border border-gray-200 
                  shadow-lg flex flex-col rounded shadow-sm bg-white transform overflow-hidden space-y-4
                  ${
                    currentProduct === slug
                      ? `ring ring-${Theme.PRIMARY_BLUE} 
                  outline-none shadow-outline 
                  border-${Theme.PRIMARY_BLUE}`
                      : ``
                  }
                  `}
                  key={index}
                  onClick={() => setCurrentProduct(slug)}
                >
                  <p class="block font-semibold text-gray-600">{name}</p>
                  <div class="flex-grow w-full space-y-4">
                    <Image
                      layout={`fullWidth`}
                      image={
                        thumbnail.localFile.childImageSharp.gatsbyImageData
                      }
                      class="rounded-lg"
                    />
                  </div>
                </button>
              ))
            ) : (
              <p class="text-gray-600">No products found</p>
            )}
          </div>
        </div>
        <div class="py-4 px-5 lg:px-6 w-full bg-gray-50 text-right space-x-1">
          <div className={`max-w-max float-right`}>
            <Button
              disabled={currentProduct === null}
              smallButton={true}
              onClick={() => {
                dispatch({
                  type: "DESIGN_LAB_UPDATE",
                  key: "productHtmlId",
                  value: currentProduct,
                })
                dispatch({
                  type: "DESIGN_LAB_UPDATE",
                  key: "openProductModal",
                  value: false,
                })
                dispatch({
                  type: "DESIGN_LAB_UPDATE",
                  key: "content",
                  value: [],
                })
                setCurrentProduct(null)
              }}
            >
              <span>Start Designing</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProductModal

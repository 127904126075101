import React from 'react';
import { FaInfo, FaCheck } from 'react-icons/fa'
import { ImCross } from "react-icons/im";


const AlertModal = ({ style, options, message, close }) => (
    //   <button onClick={close}>X</button>
    <div className="pb-1">
    {options.type === 'info' &&
        <div className="w-48 my-1 rounded-full">
        <div className="flex flex-row items-center justify-center content-between bg-blue-400 text-white text-sm font-bold rounded-full py-2 px-4" role="alert">
            <FaInfo size="1em"/>
            &nbsp;
            <p className="text-sm sm:text-sm md:text-base lg:text-base xl:text-base">{message}</p>
        </div>
        </div>
    }
    {options.type === 'success' &&
        <div className="w-48 my-1 rounded-full">
        <div className="flex flex-row items-center justify-center content-between bg-green-400 text-white text-sm font-bold rounded-full py-2 px-4" role="alert">
            <FaCheck size="1em"/>
            &nbsp;
            <p className="text-sm sm:text-sm md:text-base lg:text-base xl:text-base">{message}</p>
        </div>
        </div>
    }
    {options.type === 'error' &&
        <div className="w-48 my-1 rounded-full">
        <div className="flex flex-row items-center justify-center content-between bg-red-400 text-white text-sm font-bold rounded-full py-2 px-4" role="alert">
            <ImCross size="1em"/>
            &nbsp;
            <p className="text-sm sm:text-sm md:text-base lg:text-base xl:text-base">{message}</p>
        </div>
        </div>
    }
    </div>
  )

export default AlertModal
import { call, put } from 'redux-saga/effects'
import shopApi from '../services/ShopifyApi'

export function* getPolicy(action) {
    try {
        const payload = yield call(shopApi.getPolicy, action.query)
        yield put({type: 'POLICY_SUCCESS', payload});
    } catch (e) {
        yield put({type: "POLICY_FAILURE", message: 'Error getting policy.'});
    }
}
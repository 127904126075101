import React, { useState, useEffect } from "react"
import { SiteMetadata } from "../../Shared"
import { useDispatch, useSelector } from "react-redux"
import { getStoreState } from "../../../redux/accessors"
import { FaSadTear } from "react-icons/fa"
import ReactHtmlParser from "react-html-parser"
import { PageLayout } from "."

const FooterPage = props => {
  const dispatch = useDispatch()
  const { policy, fetched, message, error } = useSelector(getStoreState)
  const { title, description, query, type } = props
  const [bodyType, updateBodyType] = useState(null)

  useEffect(() => {
    dispatch({ type: "FETCH_POLICY", query: query })
  }, [query, dispatch])

  useEffect(() => {
    let typeMap = {
      privacy: "privacyPolicy",
      refund: "refundPolicy",
      shipping: "shippingPolicy",
      terms: "termsOfService",
    }
    updateBodyType(typeMap[type])
  }, [type])

  return (
    <PageLayout>
      <SiteMetadata title={title} description={description} />
      <div className="py-12 lg:pb-128 relative w-full lg:w-2/3 text-justify">
        <div className="bg-white absolute -right-full -left-full inset-y-0" />

        <div className="w-full md:w-1/2 xl:w-full z-10 relative">
          <p className="text-3xl leading-tight font-extrabold tracking-tight text-gray-600 sm:text-4xl mb-4">
            {title}
          </p>

          {error && message && (
            <div>
              <div className="flex flex-row items-center justify-start">
                <h2 className="text-xl leading-tight font-semibold tracking-tight text-annmade-1.0-blue sm:text-2xl">
                  Uh Oh! Looks like something is missing. Try refreshing the
                  page or coming back later. &nbsp;
                  <FaSadTear className="inline-block text-xl leading-tight font-semibold tracking-tight text-annmade-1.0-gold sm:text-2xl" />
                </h2>
              </div>
            </div>
          )}
        </div>

        {policy && fetched && bodyType && (
          <div className={`relative z-10 text-gray-500`}>
            {ReactHtmlParser(policy.shop[`${bodyType}`].body)}
          </div>
        )}
      </div>
    </PageLayout>
  )
}

export default FooterPage

// This function does a DEEP nesting of the design studio and 
// and spits out a one dimensional array for Shopify Consumption
export const DesignStudioAttributesTransform = ({ content, photoURI }) => {
  let customAttributes = [{ key: '_Photo', value: photoURI }]

  content.forEach(({ contentId, options }) => {

    const generateOneDimensionalObj = (obj) => {
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object') {
          generateOneDimensionalObj(value)
        } else {
          customAttributes.push({ key:`_${contentId}-${key}`, value: String(value) })
        }
      }
    }

    return generateOneDimensionalObj(options)
  })
  return customAttributes
}

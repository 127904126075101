import { Transition } from "../../styles"

//Listener to close open select menus
const dropDownListener = e => {
  if (
    !e.target.matches(".dropdown-item") &&
    !e.target.matches(".dropdown-button")
  ) {
    let dropdownContent = document.getElementsByClassName("dropdown-content")
    //for each value returned, check if visible, set to invisible
    for (const doc of dropdownContent) {
      if (doc.classList.contains("visible")) {
        doc.classList.remove("visible")
        doc.classList.remove(
          "transition",
          `${Transition.EASE_DEFAULT}`,
          "duration-100"
        )
        doc.classList.remove("opacity-100", "scale-100")

        doc.classList.add("opacity-0", "scale-95")
        doc.classList.add(
          "transition",
          `${Transition.EASE_DEFAULT}`,
          "duration-150"
        )
        doc.classList.add("invisible")
      }
    }
  }
}


export default dropDownListener

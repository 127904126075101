import { store } from '../config/CreateStore'
const axios = require('axios');
const qs = require('qs');

const getAccessToken= () => {
    let auth = btoa(`${process.env.SPOTIFY_CLIENT_ID}:${process.env.SPOTIFY_CLIENT_SECRET}`)
    return axios({
        method:'post',
        url: 'https://accounts.spotify.com/api/token',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${auth}`,
        },
        data: qs.stringify({
            grant_type: 'client_credentials'
        })
    }).then((res) => {
        return res
    })
}

const searchSpotify = (query) => {
    let q = `?q=${encodeURI(query)}`
    let type = `&type=${encodeURI(`track`)}`
    let limit = `&limit=10`
    let auth = store.getState().spotify.accessToken
    return axios({
        method:'get',
        url: `https://api.spotify.com/v1/search${q}${type}${limit}`,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${auth}`,
        },
    }).then((res) => {
        return res
    })
}

const functions = {
    getAccessToken,
    searchSpotify
}

export default functions
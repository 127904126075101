// Calculate height minus padding
function innerHeight(el) {
  const style = window.getComputedStyle(el, null)
  const pt = parseInt(style.getPropertyValue("padding-top"), 10)
  const pb = parseInt(style.getPropertyValue("padding-bottom"), 10)
  return el.getBoundingClientRect().height - pt - pb
}

// Calculate width minus paddings
function innerWidth(el) {
  const style = window.getComputedStyle(el, null)
  const pl = parseInt(style.getPropertyValue("padding-left"), 10)
  const pr = parseInt(style.getPropertyValue("padding-right"), 10)
  return el.getBoundingClientRect().width - pl - pr
}

function hasClass(element, cls) {
  return (" " + element.className + " ").indexOf(" " + cls + " ") > -1
}

/**
 * @param {DOMElement} element - JS element, parent container
 * @param {Number} width - Width of parent container
 * @param {Number} height - Height of parent container
 *
 * The purpose of this file is fit single and multiline text
 * to the width and height of its container
 *
 * returns the new fontSize and manipulates the node directly
 * 
 * The element is the container, but we manipulate the <p> tags within
 *
 * Similar idea in JS:
 * https://github.com/STRML/textFit/blob/master/textFit.js
 */

const textFitter = (element, width, height) => {
  let originalHTML = element.innerHTML
  let originalWidth = innerWidth(element)
  let originalHeight = innerHeight(element)

  let innerSpan
  // Add text-fitted span inside this container.
  if (originalHTML.indexOf("textFitted") === -1) {
    innerSpan = document.createElement("span")
    innerSpan.className = "textFitted draggable-content"
    // Inline block ensure it takes on the size of its contents, even if they are enclosed
    // in other tags like <p>
    innerSpan.style["display"] = "inline-block"
    innerSpan.innerHTML = originalHTML
    element.innerHTML = ""
    element.appendChild(innerSpan)
  } else {
    // Get all the children p tags
    innerSpan = element.querySelectorAll("p.draggable-content")

    // Remove vertical align if we're reprocessing.
    if (hasClass(innerSpan, "textFitAlignVert")) {
      innerSpan.className = innerSpan.className.replace("textFitAlignVert", "")
      innerSpan.style["height"] = ""
      element.className.replace("textFitAlignVertFlex", "")
    }
  }
  /* 
    Smallest font size is the size to return. This is because based on the left
    and right spacing, a p tag might have more room to expand (for example 'Add' below)
      add
    heading
  */
  let minFontSize = 1600

  for (let i = 0; i < innerSpan.length; i++) {
    let low = 16
    let mid
    let high = 1600

    // Binary search for highest best fit
    let size = low
    while (low <= high) {
      mid = (high + low) >> 1
      innerSpan[i].style.fontSize = mid + "px"
      var innerSpanBoundingClientRect = innerSpan[i].getBoundingClientRect()
      if (
        innerSpanBoundingClientRect.width <= originalWidth &&
        // we multply by number of p tags here to prevent over expanding the content box 
        innerSpanBoundingClientRect.height * innerSpan.length  <= originalHeight
      ) {
        size = mid
        low = mid + 1
      } else {
        high = mid - 1
      }
    }
    minFontSize = Math.min(size, minFontSize)
    innerSpan[i].style.fontSize = "unset"
  }
  return minFontSize
  
}

export default textFitter

import { combineReducers } from 'redux'
import DesignSongPlaque from './DesignSongPlaqueRedux'
import DesignCup from './DesignCupRedux'
import DesignStudio from './DesignStudioRedux'
import Cart from './CartRedux'
import Store from './StoreRedux'
import Spotify from './SpotifyRedux'
import Gallery from './GalleryRedux'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

/*
  We wrap reducers here in persistReducer to blacklist nested state properties
  https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
*/
const cartPersistConfig = {
    key: 'cart',
    storage: storage,
    blacklist: ['error', 'posted', 'message', 'posting']
  };

const spotifyPersistConfig = {
  key: 'spotify',
  storage: storage,
  blacklist: ['query', 'results', 'fetching', 'fetched', 'error', 'message'],
};

const rootReducer = combineReducers({ 
    songPlaque: DesignSongPlaque,
    designCup: DesignCup,
    designStudio: DesignStudio,
    cart: persistReducer(cartPersistConfig, Cart),
    spotify: persistReducer(spotifyPersistConfig, Spotify),
    store: Store,
    gallery: Gallery
})

export default rootReducer
// initial state
const initState = {
    gallery: []
}

// actions
const SET_GALLERY = 'SET_GALLERY'

export const setGallery = (gallery) => ({
    type: SET_GALLERY, gallery
});

// reducers
export default (state = initState, action) => {
    switch (action.type) {
        case SET_GALLERY:
            return { 
                ...state, 
                gallery: action.gallery
             }
        default:
            return state
    }
}
//Initial State
const initialState = {
    artist: '',
    song: '',
    croppedPhotoURI: null,
    tempPhotoURI: null,
    photoURI: null,
    modal: false,
    spotifySearchModal: false,
    buttonColor: "white",
    standID: null,
  };

//Action Creators
const SELECT_PHOTO = 'SELECT_PHOTO'
const UPLOAD_PHOTO = 'UPLOAD_PHOTO'
const REMOVE_PHOTO = 'REMOVE_PHOTO'
const SAVE_CROPPED_PHOTO = 'SAVE_CROPPED_PHOTO'
const OPEN_MODAL = 'OPEN_MODAL'
const CLOSE_MODAL = 'CLOSE_MODAL'
const UPDATE_VINYL_COLOR = 'UPDATE_VINYL_COLOR'
const UPDATE_ARTIST = 'UPDATE_ARTIST'
const UPDATE_SONG = 'UPDATE_SONG'
const UPDATE_STAND = 'UPDATE_STAND'
const SEARCH_SPOTIFY = 'SEARCH_SPOTIFY'


//Reducers
export default (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_PHOTO:
            return {
                ...state,
                tempPhotoURI: null,
                photoURI: action.photoURI
            }
        case SELECT_PHOTO:
            return {
                ...state,
                modal: true,
                tempPhotoURI: action.photoURI
            }
        case REMOVE_PHOTO:
            return {
                ...state,
                photoURI: null,
            }
        case SAVE_CROPPED_PHOTO:
            return {
                ...state,
                modal: false,
                croppedPhotoURI: action.croppedPhotoURI
            }
        case OPEN_MODAL:
            return {
                ...state,
                modal: true,
            }
        case CLOSE_MODAL:
            return {
                ...state,
                modal: false,
            }
        case UPDATE_VINYL_COLOR:
            return {
                ...state, 
                buttonColor: action.color
            }
        case UPDATE_ARTIST:
            return {
                ...state,
                artist: action.artist
            }
        case UPDATE_SONG:
            return {
                ...state,
                song: action.song
            }
        case UPDATE_STAND: {
            return {
                ...state,
                standID: action.standVariantID
            }
        }
        case SEARCH_SPOTIFY: {
            return {
                ...state,
                spotifySearchModal: action.bool
            }
        }
        default:
            return state;
    }
};
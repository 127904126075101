import _ from "lodash"

// initial state
const initState = {
  openContentModal: false,
  openProductModal: false,
  bottomNavMenuItem: "Text",
  selectedContentId: null,
  productHtmlId: null,
  editContentBottomSheet: {
    isOpen: false,
    type: null,
  },
  content: [],
}

// actions
const SET_DESIGN_STUDIO_OPTION = "SET_DESIGN_STUDIO_OPTION"

const OPEN_CONTENT_MODAL = "OPEN_CONTENT_MODAL"
const CLOSE_CONTENT_MODAL = "CLOSE_CONTENT_MODAL"

const OPEN_EDIT_CONTENT_SHEET = "OPEN_EDIT_CONTENT_SHEET"
const CLOSE_EDIT_CONTENT_SHEET = "CLOSE_EDIT_CONTENT_SHEET"

const ADD_CONTENT = "ADD_CONTENT"
const DELETE_SELECTED_CONTENT = "DELETE_SELECTED_CONTENT"
const DUPLICATE_SELECTED_CONTENT = "DUPLICATE_SELECTED_CONTENT"
const UNLOCK_SELECTED_CONTENT = "UNLOCK_SELECTED_CONTENT"
const LOCK_SELECTED_CONTENT = "LOCK_SELECTED_CONTENT"

const DESIGN_LAB_UPDATE = "DESIGN_LAB_UPDATE"
const DESIGN_LAB_UPDATE_CONTENT = "DESIGN_LAB_UPDATE_CONTENT"

const SET_BOTTOM_SHEET_TOGGLE = "SET_BOTTOM_SHEET_TOGGLE"

const SET_BOTTOM_NAV_MENU_ITEM = "SET_BOTTOM_NAV_MENU_ITEM"

const DESIGN_STUDIO_LOCK_ALL_CONTENT = "DESIGN_STUDIO_LOCK_ALL_CONTENT"

// reducers
export default (state = initState, action) => {
  switch (action.type) {
    case OPEN_CONTENT_MODAL:
      return {
        ...state,
        openContentModal: true,
      }
    case CLOSE_CONTENT_MODAL:
      return {
        ...state,
        openContentModal: false,
      }
    case ADD_CONTENT:
      const { contentId } = action.contentItem
      return {
        ...state,
        selectedContentId: contentId,
        content: [
          ...state.content,
          {
            ...action.contentItem,
            options: {
              ...action.contentItem.options,
            },
          },
        ],
      }
    case DELETE_SELECTED_CONTENT:
      return {
        ...state,
        selectedContentId: null,
        content: state.content.map(content => {
          const { contentId } = content
          return contentId === state.selectedContentId
            ? {
                ..._.set({ ...content }, "active", false),
              }
            : content
        }),
      }
    case DUPLICATE_SELECTED_CONTENT:
      const copyContent = _.cloneDeep(
        state.content.find(
          ({ contentId }) => contentId === state.selectedContentId
        )
      )
      const { options } = copyContent
      copyContent.contentId = state.content?.length
      copyContent.options.pos.left = options.pos.left + 25
      copyContent.options.pos.top = options.pos.top + 25
      copyContent.options.value = options.hiddenValue
      return {
        ...state,
        selectedContentId: state.content?.length,
        content: [...state.content, { ...copyContent }],
      }
    case LOCK_SELECTED_CONTENT:
      return {
        ...state,
        selectedContentId: null,
        content: [
          ...state.content.map(content => {
            const { contentId, options } = content
            const { hiddenValue } = options
            if (contentId === state.selectedContentId) {
              let copyContent = JSON.parse(JSON.stringify(content))
              copyContent.locked = true
              copyContent.options.value = hiddenValue
              console.log(copyContent)
              return copyContent
            }
            return content
            // return contentId === state.selectedContentId
            //   ? {
            //       ..._.set({ ...content }, "locked", true),
            //     }
            //   : content
          }),
        ],
      }
    case UNLOCK_SELECTED_CONTENT:
      return {
        ...state,
        selectedContentId: state.selectedContentId,
        content: [
          ...state.content.map(content => {
            const { contentId } = content
            return contentId === state.selectedContentId
              ? {
                  ..._.set({ ...content }, "locked", false),
                }
              : content
          }),
        ],
      }
    case DESIGN_LAB_UPDATE:
      return {
        ..._.set({ ...state }, action?.key, action?.value),
      }
    case DESIGN_LAB_UPDATE_CONTENT:
      const { key, value } = action
      return {
        ...state,
        content: state.content.map(content => {
          const { contentId } = content
          return contentId === state.selectedContentId
            ? {
                ..._.set({ ...content }, key, value),
              }
            : content
        }),
      }
    case SET_DESIGN_STUDIO_OPTION:
      return {
        ...state,
        currentOption: action.option,
      }
    case SET_BOTTOM_SHEET_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    case SET_BOTTOM_NAV_MENU_ITEM:
      return {
        ...state,
        bottomNavMenuItem: action.item,
      }
    case OPEN_EDIT_CONTENT_SHEET:
      return {
        ...state,
        editContentBottomSheet: {
          isOpen: true,
          type: action.editContentType,
        },
      }
    case CLOSE_EDIT_CONTENT_SHEET:
      return {
        ...state,
        editContentBottomSheet: {
          isOpen: false,
        },
      }
    case DESIGN_STUDIO_LOCK_ALL_CONTENT:
      return {
        ...state,
        content: [
          ...state.content.map(content => {
            return {
              ..._.set({ ...content }, "locked", true),
            }
          }),
        ],
      }
    default:
      return state
  }
}

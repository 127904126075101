/*
Wrap the root element in your Gatsby markup once using wrapRootElement, 
an API supporting both Gatsby’s server rendering and browser JavaScript processes.

https://www.gatsbyjs.com/docs/adding-redux-store/
*/
import "@fontsource/dancing-script/500.css" // Weight 500 with all styles included.
import "./src/styles/style.css"

import React from 'react';
import RootContainer from './src/config/RootContainer'
import { stripIndent } from 'common-tags';
export { default as wrapRootElement } from './src/config/ReduxWrapper';

//Adds the Facebook Script needed for the gallery
export const onRenderBody = ({ setPreBodyComponents }, pluginOptions) => {
  return setPreBodyComponents([
    (<script
      key={`gatsby-plugin-facebook-sdk`}
      dangerouslySetInnerHTML={{
        __html: stripIndent`
          fbAsyncInit = function() {
            FB.init(${JSON.stringify(pluginOptions)});
          };
          (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js"
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        `}}
      />),
  ]);
};
export const wrapPageElement = ( { element } ) => {
    return (
    <RootContainer>
        {element}
    </RootContainer>
    )
}